.App {
  text-align: center;
  background-image: url("/src/assets/images/background-doctor2.png");
  height: 90vh;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100vw 100vh;
  padding: 0 0;
  padding-top: 10px;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    height: 150px;
    width: 180px;
    margin-right: 50px;
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  /* align-items: flex-end;
  justify-content: flex-end; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
  background: rgba(255, 255, 255, 0.5) !important;
}

.hidden {
  display: none;
}

.options {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.title {
  font-weight: bold;
  margin-top: 110px;
  margin-right: 800px;
}

.App-link {
  color: #61dafb;
}

.search-input {
  width: 500px;
  height: 40px;
  border-radius: 20px;
  color: #000;
  font-weight: 300;
  margin-top: 100px;
  border-color: #e8e8e8;
  transition: 0.2s;
  padding-left: 15px;
}

.btn {
  height: 50px;
  width: 120px;
  margin-top: 20px;
}

.btn-sm {
  height: 30px;
  width: 100px;
  margin-top: 0px;
}

.btn-pink-moon {
  background: #ec008c;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #fc6767, #ec008c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: #ff0000;
  color: #fff;
  border: 3px solid #eee;
}

.btn-rounded {
  border-radius: 35px;
}

.modal-header {
  padding-right: 3rem !important;
  padding-left: 1rem !important;
}

.modal-title {
  font-size: 16px;
  font-weight: 600;
  height: 50%;
}

.modal-100w {
  width: 90vw;
  max-width: 90vw;
}

.email-input {
  border-radius: 10px;
  height: 30px;
  padding-left: 10px;
}

.bottom-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  background: rgba(255, 255, 255, 0.5) !important;
  justify-content: center;
  align-items: center;
  height: 40px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 550px) {
  .App {
    background-image: url("/src/assets/images/background-doctor2.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
  }
  .title {
    font-weight: bold;
    margin-left: 50px;
    width: 5.2rem;
  }
  
  .search-input {
    width:  60%;
    height: 40px;
    border-radius: 20px;
    color: #000;
    font-weight: 300;
    margin-top: 100px;
    border-color: #e8e8e8;
    transition: 0.2s;
    padding-left: 15px;
  }

  .bottom-content {
    position: absolute;
    bottom: 0;
    width: 100% !important;
    display: flex;
    background: rgba(255, 255, 255, 0.5) !important;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
}

.fbutton{
  background-color:#4267B2;
  font-family: "Google Sans",arial,sans-serif;
    font-weight: 500;
    border-radius: 4px;
    box-sizing: border-box;
    background-image: none;
    border: 1px solid #dadce0;
    color: #fff;
    font-family: "Google Sans",arial,sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
}
.errorMsg{
  color: red;
  font-size: 0.7rem;
}
@media screen and (max-width: 995px) {
  .registerImg  {
        display:none !important;
}
.phoneDropdown{
  width: 30% !important;
}

.animationDropdown {
  width: 100% !important;
  border-bottom-style: yellow;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  outline: none;
}
.animationInput {
  width: 100% !important;
  border-bottom-style: red;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  outline: none;
}

.name-div{
  width: 50% !important;
  height: 20%;
  display: inline-block;
}
.name-error{
  width: 90% !important;
  margin-left: 5% !important;
}
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.animationInput {
  width: 60%;
  border-bottom-style: red;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  outline: none;
}

.animationInput2 {
  width: 100%;
  border-bottom-style: red;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  outline: none;
}
.animate{
  animation: fadeIn 1s;
}
.animationDropdown {
  width: 60%;
  border-bottom-style: red;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  outline: none;

}
.animationInput:focus{
  background-color:#eee;
}

.facebook{
  color: white;
  border-radius: 8px;
  background-color: #3b5998;
  outline: none;
  text-decoration: none;
  border: none;
  padding: 10px;
  font-size: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.emailBtn{
  text-decoration: none;
  border: none;
  background-color:	#333333;
  color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.google{
  text-decoration: none;
  border: none;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  padding: 10px;
  border-radius: 8px;
}

ul.social > li > a:hover {
  text-decoration: none;
  background-color: #ee7c22;
}

.registerationButton{ 
  position: absolute;
  display:flex;
  bottom: 3%;
  gap: 150%;
}

.registerationButton2{ 
  position: absolute;
  display:flex;
  bottom: 3%;
  right: 4%;
}
.loginButton  {
  display: flex;
  position: absolute;
  bottom: 3%;
  right: 3%;

}
@media screen and (max-width: 768px) {
  .registerationButton{ 
    display:flex;
    bottom: 3%;
    margin-top: 45%;
    gap: 40%;
    position: relative;
  }
.errorMsg{
  z-index: 3;
  color: red;
  font-size: 0.6rem;
}
.loginButton  {
  display: flex;
  position: absolute;
  bottom: 3%;
  right: 3%;
}
.registerationButton2{
  display: flex;
  position: absolute;
  bottom: 3%;
  right: 4%;
}
.registerationButtons{
  width: 100% !important;
}
.have-account{
  font-size: 12px;
}
}
.checkbox{
  margin-top: 20px;
  align-items: left;
}

.exit{
  float:right;
  cursor: pointer;
}

.name{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
  
}

.name-div{
  position:relative;
  width: 29%;
  height: 20%;
  display: inline-block;

}

hr.solid {
  border-top: 3px solid black;
}

.line {
  position: relative;
  text-align: center;
}
.line:after {
  content: "";
  border-bottom: 1px solid black;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}
.line span {
  padding: 0 8px;
  background-color: white;
  position: relative;
  z-index: 2;
}

.name-error{
  display: grid;
  margin-left: 21%;
  width: 60%;
}

.phoneDropdown{
  display: inline-flex;
  align-items: center;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  outline: none;
  width: 11%;
  text-align: start;
  text-indent: 1px;
  text-overflow: '';
}

.sidebar{
  z-index: -1
}