.navigation {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0.5rem 0rem;
    background: rgba(255, 255, 255, 0.5) !important;
    color: black;
   
  }
  .downArrow::after {
    display: none !important; 
  }

  .btn:focus {
    outline: none;
    box-shadow: none;
  }
  
  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 24px;
    margin-left: 1rem;
    font-weight: 900;
  }
  
  .navigation-menu {
    margin-left: auto;
    margin-right: 240px;
  }
  
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  
  .navigation-menu li a {
    list-style-type: none;
    margin: 0 2rem;
    color: black;
  }
  
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }
  
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  
  .hamburger:hover {
    background-color: #2642af;
  }

  .search-bar{
    width: 30%;
  }
  
  @media screen and (max-width: 768px) {
    .brand-name {
      text-decoration: none;
      color: black;
      font-size: 24px;
      margin-left: 1rem;
      font-weight: 900;
      position: absolute;
    }

    .search-bar{
      margin: 0 0;
      min-width: 70%;
    }

    .hamburger {
      display: block;
    }
  
    .navigation-menu ul {
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      height: calc(100vh - 60px);
      background-color: white;
      border-top: 1px solid black;
      display: none;
      list-style-type: none;
    }
  
    .navigation-menu li {
      text-align: center;
      margin: 0;
      list-style-type: none;
      display: flex;
      justify-content: center;
    }
  
    .navigation-menu li a {
      text-align: center;
      color: black;
      width: 100%;
      padding: 1.5rem 0;
      list-style-type: none;
    }

    .a {
      color: black;
    }
  
    .navigation-menu li:hover {
      background-color: #eee;
    }
  
    .navigation-menu.expanded ul {
      display: block;
    }
  }
  
  