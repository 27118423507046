@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #4723d9;
  --first-color-light: #afa5d9;
  --white-color: #F6FFFf;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}
*,
::before,
::after {
  box-sizing: border-box;
}
.collapse{
  transition: .5;
}
a {
  text-decoration: none;
}
.l-navbar {
  width: 100% !important;
  height: 100%;
  background-color:#F6FFFf;
  transition: 0.5s;
}
.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.nav_logo{
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 0.8rem;
  font-weight: 700;
  text-decoration: none;
}


.nav_link {
  
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: 0.5rem 0 0.5rem 1.5rem;
    font-weight: 700;
    text-decoration: none;
  
}
.nav_logo {
  margin-bottom: 2rem;
}
.nav_logo-icon {
  font-size: 1.25rem;
  color: #4723d9;
}
.nav_logo-name {
  color: #4723d9;
  font-weight: 700;
  font-size: 2rem;
 
}
.nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1rem;
  transition: 0.3s;
  text-decoration: none;
}
.nav_link:hover {
  color: #4723d9;
}
.nav_icon {
  font-size: 1.25rem;
}
.show {
  left: 0;
}
.active {
  color: #4723d9;
}
.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: 32px;
  border-radius: 4px;
  background-color: #4723d9;
}
.height-100 {
  height: 100vh;
}

@media screen and (min-width: 768px) {
  
  .l-navbar {
    left: 0;

  }
}


@media screen and (max-width: 768px) {
  
 .disappear{
  display: none;
  height: 10px;
  z-index: 5;
  position: relative;
  
 }
 .appear{
  display: block;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: #F6FFFf;
  position: absolute;  
  top: 0;
 }
 @keyframes easeIn {
  0% { width: 0; }
  100% { width: 50%; }
}
.animation{
animation: easeIn 1s;
}
}
