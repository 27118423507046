.flex-container{
    display: flex !important;
}

.input-field{
    width: 45% !important;
}

hr{
    border-radius: 3px;
    border: 1px solid #C0C0C0;
    margin: auto;
    width: 96%;
}

.form-label {
  font-weight: 600;
}

@media screen and (max-width: 550px) {
  .input-width{
    width: 10% !important;
  }
    .flex-container{
        display: block !important;
    }
    .input-field{
        width: 100% !important;
    }
}
.tabset > input[type="radio"] {
    position: absolute;
    left: -200vw;
  }
  
  .tabset .tab-panel {
    display: none;
  }
  
  .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
  .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
  .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
  .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
  .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
  .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
    display: block;
  }
  
  /*
   Styling
  */

  
  .tabset > label {
    position: relative;
    display: inline-block;
    padding: 10px 10px 10px;
    border: 1px solid transparent;
    border-bottom: 0;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px !important;
  }
  
  
  .tabset > label:hover,
  .tabset > input:focus + label {
    color: #06c;
  }
  
  .tabset > label:hover::after,
  .tabset > input:focus + label::after,
  .tabset > input:checked + label::after {
    background: #06c;
  }
  
  .tabset > input:checked + label {
    border-color: #ccc;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
    border-top-left-radius : 8px;
border-top-right-radius : 8px;
  }
  
  .tab-panel {
    padding: 30px 0;
    border-top: 1px solid #ccc;
  }
  
  /*
   Demo purposes only
  */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  .tabset {
    max-width: 100%;
  }